<template>
  <div>
    <KTCodePreview v-bind:title="'Phiếu trả hàng'">
      <template v-slot:preview>
        <b-form ref="form" lazy-validation class="row">
          <b-container>
            <b-col>
              <b-row>
                <p class="text-danger mr-2" v-if="returnBillNumber">
                  Hóa đơn đã được tạo phiếu trả hàng. Vui lòng xem kỹ tại đây:
                </p>
                <div
                  @click="editItem(returnBillNumber)"
                  style="cursor: pointer; text-decoration: underline"
                >
                  <span v-text="returnBillNumber"></span>
                </div>
              </b-row>
            </b-col>
          </b-container>
          <b-container>
            <b-row class="col-md-12 mb-3 m-0 p-0">
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Ngày bán hàng:</label>
                  <p class="ml-2">{{ saleInvoice.createdAt }}</p>
                </div>
              </b-col>
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Nhân viên bán hàng:</label>
                  <p class="ml-2">{{ saleInvoice.saleName }}</p>
                </div>
              </b-col>
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Khách đã thanh toán:</label>
                  <p
                    class="ml-2 textTotalPayment"
                    v-if="saleInvoice.totalPayment > 0"
                  >
                    {{ convertPrice(saleInvoice.totalPayment) }}
                  </p>
                  <p class="ml-2 textTotalPayment" v-else>
                    Khách chưa thanh toán
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row class="col-md-12 mb-3 m-0 p-0">
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Mua tại cửa hàng:</label>
                  <p class="ml-2">{{ saleInvoice.storeName }}</p>
                </div>
              </b-col>
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Nhân viên thu ngân:</label>
                  <p class="ml-2">{{ saleInvoice.cashierNameBill }}</p>
                </div>
              </b-col>
              <b-col md="4">
                <div class="row">
                  <label class="textTitleFinal">Khách dùng tiêu điểm:</label>
                  <p class="ml-2 textTotalPayment">
                    {{ convertPrice(saleInvoice.totalAmountPoint) }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <b-container>
            <b-col>
              <b-row>
                <label class="textTitleFinal"
                  >Chiết khấu trên tổng hóa đơn:</label
                >
                <p class="ml-2 textTotalPayment">
                  {{ convertPrice(saleInvoice.discountValue) }}
                </p>
              </b-row>
            </b-col>
          </b-container>
          <b-container>
            <b-col>
              <b-row>
                <p class="text-danger">
                  <span>&#42;</span> Chiếu khấu trên tổng hóa đơn và tiền tiêu
                  điểm sẽ được chia cho từng sản phẩm theo tỉ lệ đóng góp doanh
                  thu của sản phẩm vào doanh thu hóa đơn khi tiến hành trả hàng.
                </p>
              </b-row>
            </b-col>
          </b-container>
          <b-container class="bv-example-row">
            <b-row>
              <b-col md="9">
                <p class="textPayment">Thông tin sản phẩm</p>
                <b-row>
                  <b-col>
                    <table
                      class="
                        table table-bordered table-vertical-center table-hover
                      "
                    >
                      <thead>
                        <tr>
                          <th class="headerTable" style="textalign: center">
                            STT
                          </th>
                          <th class="headerTable">Sản phẩm</th>
                          <th class="headerTable">SL</th>
                          <th class="headerTable">Giá</th>
                          <th class="headerTable">CK phân bổ</th>
                          <th class="headerTable">Tiêu điểm phân bổ</th>
                          <th class="headerTable">Thành tiền</th>
                          <th class="headerTable">Chiết khấu</th>
                          <th v-if="true"></th>
                        </tr>
                      </thead>

                      <tbody v-for="item in listBillItem" :key="item.id">
                        <ReturnBillItem
                          v-bind:productItem="item"
                          v-bind:editable="true"
                          v-on:deleteItem="deleteItemInArray"
                          v-on:update="updateItem"
                          v-on:checkValidImei="checkValidImei"
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style="text-align: right" colspan="5">
                            Tổng phải trả:
                          </th>
                          <td style="text-align: right; padding: 0px 20px">
                            {{ convertPrice(totalPrice) }}
                          </td>
                          <td style="text-align: right; padding: 0px 20px"></td>
                          <td style="text-align: right; padding: 0px 20px"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Họ tên:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.customerName"
                        placeholder="Họ tên khách hàng"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Nhân viên thu ngân:</label>
                      <vue-autosuggest
                        aria-describedby="input-phone-live-feedback"
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedCash"
                        :limit="10"
                        @input="onInputChangeCash"
                        :input-props="{
                          class: 'autosuggest__input',
                          placeholder: 'Nhân viên thu ngân',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="mainModel.cashierName"
                      ></vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group class="required-control">
                      <label>Điện thoại:</label>
                      <vue-autosuggest
                        aria-describedby="input-phone-live-feedback"
                        :suggestions="filteredOptionsCustomer"
                        :limit="10"
                        :input-props="{
                          class: 'autosuggest__input',
                          placeholder: 'SĐT Khách hàng',
                          disabled: true,
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="mainModel.customerMobile"
                      ></vue-autosuggest>
                      <b-form-invalid-feedback
                        :state="validateCustomer()"
                        id="input-phone-live-feedback"
                      >
                        Vui lòng nhập số điện thoại
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Thành phố:</label>
                      <b-form-select
                        class="select-style"
                        v-model="mainModel.customerCity"
                        :options="listCity"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        v-on:change="getDistrict"
                        :disabled="true"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn thành phố</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Quận huyện:</label>
                      <b-form-select
                        class="select-style"
                        v-model="mainModel.customerDistrict"
                        :options="listDistrict"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        v-on:change="getWard"
                        :disabled="true"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn quận</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Phường xã:</label>
                      <b-form-select
                        class="select-style"
                        v-model="mainModel.customerWard"
                        :options="listWard"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        :disabled="true"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn phường</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Địa chỉ:</label>
                      <b-form-input
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="mainModel.customerAddress"
                        placeholder="Địa chỉ khách hàng"
                        :disabled="true"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label>Nguồn khách hàng:</label>
                      <b-form-select
                        v-model="mainModel.orderSourceId"
                        :options="listCustomerSource"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        class="select-style"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn nguồn khách hàng:</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <label for="input-name">Ghi chú bán hàng:</label>
                      <b-form-textarea
                        size="sm"
                        class="input-style"
                        v-model="mainModel.customerNote"
                        :placeholder="'Thêm ghi chú'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <label>Ghi chú nội bộ:</label>
                      <b-form-textarea
                        size="sm"
                        class="input-style"
                        v-model="mainModel.saleNote"
                        :placeholder="'Thêm ghi chú'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="3">
                <p class="textPayment">Thanh toán</p>
                <b-form-group>
                  <label>Cửa hàng:</label>
                  <vue-autosuggest
                    class="border-radius-none"
                    aria-describedby="input-store-live-feedback"
                    :suggestions="filteredOptions"
                    @selected="onSelectedStore"
                    :limit="10"
                    @input="onInputChangeStore"
                    :input-props="inputSearchStoreProp"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                    v-model="$v.storeName.$model"
                    :state="validateState('storeName')"
                  ></vue-autosuggest>
                  <b-form-invalid-feedback
                    :state="validateState('storeName')"
                    id="input-store-live-feedback"
                    >Vui lòng nhập cửa hàng</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group>
                  <label>Phí trả hàng</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.returnFee"
                    placeholder="Nhập phí trả hàng"
                    v-mask="mask"
                  ></b-form-input>
                </b-form-group>
                <b-form-group>
                  <label>Tiền mặt</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.cashAmount"
                    placeholder="Nhập số tiền mặt"
                    v-mask="mask"
                    @change="onChangeMoney(1)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-show="checkCashAmount()">
                  <label>Tài khoản tiền mặt:</label>
                  <AutoSuggest
                    :model="selectedCashAcc"
                    :suggestions="filteredCashAccOptions"
                    placeholder="tài khoản tiền mặt"
                    :limit="10"
                    @select="onSelected"
                    @change="onInputChange"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <span>{{ suggestion.item.name }}</span>
                      </div>
                    </template>
                  </AutoSuggest>
                </b-form-group>
                <b-form-group>
                  <label>Tiền chuyển khoản:</label>
                  <b-form-input
                    class="input-style text-right"
                    size="sm"
                    type="text"
                    v-model="mainModel.transferAmount"
                    placeholder="Nhập số tiền chuyển khoản"
                    v-mask="mask"
                    @change="onChangeMoney(2)"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-show="checkTransferAmount()">
                  <label for="input-transfer-account"
                    >Tài khoản chuyển khoản:</label
                  >
                  <AutoSuggest
                    :model="mainModel.transferAccountName"
                    :suggestions="filteredTransferOptions"
                    placeholder="tài khoản chuyển khoản"
                    :limit="10"
                    @select="onSelectedTransfer"
                    @change="onInputChangeTransfer"
                  >
                    <template #custom="{ suggestion }">
                      <div>
                        <span>{{ suggestion.item.name }}</span>
                      </div>
                    </template>
                  </AutoSuggest>
                </b-form-group>
                <b-form-group v-if="checkTransferAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerBankName"
                    placeholder="Ngân hàng của khách"
                  ></b-form-input>
                </b-form-group>
                <b-form-group v-if="checkTransferAmount()">
                  <b-form-input
                    class="input-style"
                    size="sm"
                    type="text"
                    v-model="mainModel.customerTransferNo"
                    placeholder="STK của khách"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-form>
      </template>

      <template v-slot:foot>
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              tabindex="15"
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              >Lưu</b-button
            >
            <router-link to="/orders" tag="button">
              <b-button
                tabindex="16"
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
#select-product {
  border-radius: 0px;
  /* border-bottom-right-radius:0px;
border-top-right-radius:0px; */
  box-shadow: none;
  border: 1px solid #ced4da;
}
.select-style {
  border-radius: 0.28rem;
  box-shadow: none;
  border: 1px solid #ced4da;
}
.input-style {
  border: 1px solid #ced4da;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #babac5;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #babac5;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #babac5;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ReturnBillItem from '../../components/ReturnBillItem.vue';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import {
  currencyMask,
  unMaskPrice,
  removeAccents,
  xoa_dau,
  cloneDeep,
} from '@/utils/common';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
// import moment from 'moment';
import { AMOUNT_TYPE } from '@/utils/enum';
import AutoSuggest from '@/view/base/auto-sugguest/AutoSuggest';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { TIME_TRIGGER } from '@/utils/constants';
export default {
  mixins: [validationMixin],
  data() {
    return {
      isValidImei: true,
      mask: currencyMask,
      listStore: [],
      listCustomerSource: [],
      listCity: [],
      listDistrict: [],
      listWard: [],
      listBillItem: [],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      storeName: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      filteredCashAccOptions: [],
      filteredTransferOptions: [],
      cashAccOptions: [],
      listCashAccSearch: [],
      listAccSearch: [],
      accountantOptions: [],
      listEmployeeSearch: [],
      selectedCashAcc: '',
      isSubmit: false,
      billNumber: null,
      returnBillNumber: null,
      isNew: true,
      inputSearchStoreProp: {
        class: 'autosuggest__input',
        placeholder: 'Tên cửa hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      saleInvoice: {
        createdAt: '',
        saleName: '',
        totalPayment: 0,
        storeName: '',
        cashierNameBill: '',
        totalAmountPoint: 0,
        discountValue: 0,
      },
      mainModel: {
        relatedBillId: null,
        orderSourceId: null,
        customerId: null,
        customerCity: null,
        customerDistrict: null,
        customerWard: null,
        customerAddress: '',
        customerName: '',
        customerMobile: '',
        transferAmount: 0,
        transferAccountId: null,
        transferAccountName: '',
        transferAccountcode: 0,
        cashAmount: 0,
        cashAccountId: null,
        cashAccountCode: '',
        cashAccountName: '',
        saleNote: '',
        customerNote: '',
        cashierName: '',
        returnFee: 0,
        customerBankName: '',
      },
    };
  },
  validations: {
    storeName: {
      required,
    },
  },
  components: {
    KTCodePreview,
    ReturnBillItem,
    AutoSuggest,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === 1) {
          const totalPrice = unMaskPrice(element.price) * element.quantity;
          // Check discount type
          if (parseInt(element.discountType) === AMOUNT_TYPE.PERCENT) {
            let discountAmount = totalPrice * unMaskPrice(element.discount);
            total +=
              totalPrice -
              (discountAmount / 100).toFixed(0) -
              element.allotmentDiscount -
              element.allotmentPointAmount;
          } else {
            total +=
              totalPrice -
              unMaskPrice(element.discount) -
              element.allotmentDiscount -
              element.allotmentPointAmount;
          }
        } else if (element.billItemType === 2 && element.checked === false) {
          total -= unMaskPrice(element.returnPrice) * element.quantity;
        } else if (element.billItemType === 3 && element.checked === true) {
          total +=
            unMaskPrice(element.returnPrice) * element.quantity -
            element.allotmentDiscount -
            element.allotmentPointAmount;
        }
      }
      return total;
    },
    selectedClassStore() {
      const store = this.listStore.find((s) => s.name === this.storeName);
      return store ? store.id : null;
    },
    selectedEmployeeCashier() {
      const employee = this.listEmployeeSearch.find(
        (e) => e.fullName === this.mainModel.cashierName
      );
      return employee ? employee.id : null;
    },
  },
  created() {
    this.fetchStore();
    this.getListOrderSource();
    this.getListEmployee();
    this.fetchAccountant();
    this.fetchCashAccountant();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu trả hàng', route: '/returnBills' },
      { title: 'Danh sách phiếu trả hàng', route: '/returnBills' },
      { title: 'Thêm mới phiếu trả hàng' },
    ]);
    this.billNumber = this.$route.query.id;
    this.getBillById();
    this.getReturnBillByBillId();
  },
  methods: {
    checkValidImei(value) {
      this.isValidImei = value;
    },
    onSelectedStore(option) {
      this.storeName = option.item;
      this.fetchAccountant();
      this.fetchCashAccountant();
      this.mainModel.transferAmount = 0;
      this.mainModel.cashAmount = 0;
      this.selectedCashAcc = '';
      this.mainModel.cashAccountName = '';
      this.mainModel.cashAccountCode = '';
    },
    onSelectedCash(option) {
      this.mainModel.cashierName = option.item;
    },
    validateState(name) {
      if (this.isSubmit) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      }
    },
    fetchCity() {
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      ApiService.get(`city/${this.mainModel.customerCity}/district`).then(
        (data) => {
          this.listDistrict = data.data.data;
        }
      );
    },
    getWard() {
      ApiService.get(`city/${this.mainModel.customerDistrict}/ward`).then(
        (data) => {
          this.listWard = data.data.data;
        }
      );
    },
    onInputChangeStore(text) {
      if (text === '') {
        this.inputSearchProductProp.disabled = false;
      }
      this.storeName = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onInputChangeCash(text) {
      if (!text) {
        text = '';
      }
      this.mainModel.cashierName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        this.listStore = stores;
        stores.map((element) => {
          this.options[0].data.push(element.name);
        });
        let tmp = [{ data: this.options[0].data }];
        this.filteredOptions = tmp;
      });
    },
    getListOrderSource() {
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
    getListEmployee() {
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        this.listEmployeeSearch = employees;
        employees.map((element) => {
          let employeeName = element.fullName;
          this.optionsEmployee[0].data.push(employeeName);
        });
      });
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    deleteItemInArray(id) {
      const listNew = this.listBillItem.filter(
        (billItem) => billItem.id !== id
      );
      this.listBillItem = [];
      listNew.forEach((element) => {
        this.listBillItem.push(element);
      });
      this.listBillItem.forEach((element, index) => {
        if (element.billItemType === 1) {
          this.listBillItem[index].stt = index + 1;
        }
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].productId = item.productId;
          this.listBillItem[index].price = item.price
            ? parseInt(item.price)
            : 0;
          this.listBillItem[index].quantity = item.quantity
            ? parseInt(item.quantity)
            : 0;
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount;
        }
      });
    },
    onSubmit() {
      this.isSubmit = true;
      if (!this.listBillItem || !this.listBillItem.length) {
        makeToastFaile('Bạn chưa chọn sản phẩm nào!');
        this.isSubmit = false;
        return;
      }
      if (!this.isValidImei) {
        makeToastFaile('Mã IMEI không hợp lệ để bán!');
        this.isSubmit = false;
        return;
      }
      if (!this.isValidImeiCode(this.listBillItem)) {
        makeToastFaile('Vui lòng nhập IMEI cho sản phẩm IMEI');
        this.isSubmit = false;
        return;
      }

      if (
        !this.validateTransfer() ||
        !this.validateCash() ||
        !this.validateCustomer()
      ) {
        this.makeToastFaile('Dữ liệu không hợp lệ');
        return;
      }
      let discountAmount = 0;
      const listPro = this.listBillItem.map((element) => {
        discountAmount +=
          element.allotmentDiscount + element.allotmentPointAmount;
        return {
          id: element.id,
          productId: element.productId,
          productPrice: element.price ? unMaskPrice(element.price) : 0,
          quantity: element.quantity,
          discountAmount: element.discount ? unMaskPrice(element.discount) : 0,
          imeiCode: element.imeiCode,
          productType: element.billItemType,
          accessoryGroupId: element.accessoryGroupId,
          accessoryGroupCode: element.accessoryGroupCode,
          repurchasePrice: element.returnPrice
            ? unMaskPrice(element.returnPrice)
            : 0,
          isGiftTaken: element.checked,
          note: '',
          belongBillItemId: element.parentId,
          discountType: element.discountType,
          merchantId: element.merchantId || 1,
        };
      });
      const data = {
        customerId: this.mainModel.customerId,
        customerMobile: this.mainModel.customerMobile,
        customerName: this.mainModel.customerName,
        customerAddress: this.mainModel.customerAddress,
        customerDistrict: this.mainModel.customerDistrict,
        customerCity: this.mainModel.customerCity,
        customerWard: this.mainModel.customerWard,
        storeId: this.selectedClassStore,
        cashierId: this.selectedEmployeeCashier,
        customerNote: this.mainModel.customerNote,
        saleNote: this.mainModel.saleNote,
        transferAmount: this.mainModel.transferAmount
          ? unMaskPrice(this.mainModel.transferAmount)
          : 0,
        transferAccountID: this.mainModel.transferAccountId,
        transferAccountName: this.mainModel.transferAccountName,
        transferAccountCode: this.mainModel.transferAccountCode,
        cashAmount: this.mainModel.cashAmount
          ? unMaskPrice(this.mainModel.cashAmount)
          : 0,
        cashAccountId: this.mainModel.cashAccountId,
        cashAccountCode: this.mainModel.cashAccountCode,
        cashAccountName: this.mainModel.cashAccountName,
        listBillItem: listPro,
        orderSourceId: this.mainModel.orderSourceId,
        relatedBillId: this.mainModel.relatedBillId,
        returnFee: this.mainModel.returnFee
          ? unMaskPrice(this.mainModel.returnFee)
          : 0,
        customerTransferNo: this.mainModel.customerTransferNo,
        customerBankName: this.mainModel.customerBankName,
        discountAmount: discountAmount,
      };
      if (this.isNew === true) {
        this.isNew = false;
        ApiService.post('bills/refund-bill', data)
          .then(({ data }) => {
            if (data.status === 1) {
              makeToastSuccess(data.message);
              setTimeout(() => {
                this.$router.push({
                  path: '/returnBills',
                });
              }, TIME_TRIGGER);
            } else {
              makeToastFaile(data.message);
              this.isNew = true;
            }
          })
          .catch(({ response }) => {
            if (response.data.message) {
              makeToastFaile(response.data.message);
              this.isNew = true;
            } else {
              makeToastFaile(response.$error);
              this.isNew = true;
            }
          });
      }
    },
    checkTransferAmount() {
      return unMaskPrice(this.mainModel.transferAmount) > 0;
    },
    checkCashAmount() {
      return unMaskPrice(this.mainModel.cashAmount) > 0;
    },
    fetchAccountant: async function () {
      this.listAccSearch = [];
      const params = {
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        this.accountantOptions = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            const accountant = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.listAccSearch.push(accountant);
            this.accountantOptions.push(accountant);
          }
        });
        this.filteredTransferOptions = cloneDeep(this.accountantOptions);
      });
    },
    onInputChange(text) {
      const filteredData = this.cashAccOptions
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = filteredData;
    },
    onSelected(option) {
      this.selectedCashAcc = option.item.name;
      this.mainModel.cashAccountName = option.item.name;
      this.mainModel.cashAccountId = option.item.id;
      this.mainModel.cashAccountCode = option.item.code;
    },
    onInputChangeTransfer(text) {
      const filteredData = this.accountantOptions
        .filter((item) => {
          return (
            removeAccents(item)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredTransferOptions = filteredData;
    },
    onSelectedTransfer(option) {
      this.mainModel.transferAccountName = option.item.name;
      this.mainModel.transferAccountId = option.item.id;
      this.mainModel.transferAccountCode = option.item.code;
    },
    fetchCashAccountant: async function () {
      this.listCashAccSearch = [];
      if (this.selectedClassStore !== null) {
        let params = {
          storeId: this.selectedClassStore,
          type: 1,
        };
        ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions = [];
            response.data.data.forEach((item) => {
              if (item.level >= 2) {
                const accountant = {
                  id: item.id,
                  name: item.name,
                  code: item.code,
                };
                this.listCashAccSearch.push(accountant);
                this.cashAccOptions.push(accountant);
              }
            });
            this.filteredCashAccOptions = cloneDeep(this.cashAccOptions);
          }
        );
      } else {
        const params = {
          type: 1,
        };
        ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions = [];
            response.data.data.forEach((item) => {
              const accountant = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.listCashAccSearch.push(accountant);
              this.cashAccOptions.push(accountant);
            });
            this.filteredCashAccOptions = cloneDeep(this.cashAccOptions);
          }
        );
      }
    },
    isValidImeiCode(listBillItem) {
      let check = true;
      for (let index = 0; index < listBillItem.length; index++) {
        const element = listBillItem[index];
        if (element.type === 2 && element.imeiCode.trim() === '') {
          check = false;
          break;
        }
      }
      return check;
    },
    validateTransfer() {
      let check = true;
      if (
        unMaskPrice(this.mainModel.transferAmount) > 0 &&
        this.mainModel.transferAccountName.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCash() {
      let check = true;
      if (
        unMaskPrice(this.mainModel.cashAmount) > 0 &&
        this.selectedCashAcc.trim() === '' &&
        this.isSubmit
      ) {
        check = false;
      }
      return check;
    },
    validateCustomer() {
      let check = true;
      if (this.mainModel.customerMobile.trim() === '' && this.isSubmit) {
        check = false;
      }
      return check;
    },
    getBillById() {
      ApiService.get(`bills/${this.billNumber}`).then((data) => {
        this.saleInvoice.discountValue = data.data.data.discountValue;
        const totalAmount =
          data.data.data.totalAmount + this.saleInvoice.discountValue;
        this.saleInvoice.totalAmountPoint = data.data.data.pointToMoney;
        let proSTT = 1;
        data.data.data.billItems.forEach((element) => {
          if (
            element.billItemType === 1 ||
            (element.billItemType === 3 && element.isGiftTaken === true)
          ) {
            let price = element.productPrice;
            if (element.billItemType === 3) {
              price = element.repurchasePrice;
            }
            const rate = (element.quantity * price) / totalAmount;
            const allotmentDiscount = Math.ceil(
              this.saleInvoice.discountValue * rate.toFixed(1)
            );
            const allotmentPointAmount = Math.ceil(
              this.saleInvoice.totalAmountPoint * rate.toFixed(1)
            );
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: element.productCode,
              price: price,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              allotmentDiscount: this.roundMoney(allotmentDiscount),
              allotmentPointAmount: this.roundMoney(allotmentPointAmount),
              totalPrice:
                element.quantity * price -
                element.discountAmount -
                this.roundMoney(allotmentDiscount) -
                this.roundMoney(allotmentPointAmount),
              discount: element.discountAmount,
              discountType: element.discountType,
              comboItems: element.listProductInCombo,
              billItemType: 1,
              checked: true,
              returnPrice: 0,
              accessoryGroupId: '',
              accessoryGroupCode: '',
              stt: proSTT,
              merchantId: element.merchantId || 1,
            };
            this.listBillItem.push(billItem);
            proSTT++;
          } else if (
            element.billItemType === 2 &&
            element.isGiftTaken === true
          ) {
            const code = `Quà tặng kèm- ${element.accessoryGroupCode}`;
            const billItem = {
              id: element.id,
              productId: element.productId,
              name: element.productName,
              code: code,
              price: element.productPrice,
              type: element.productType,
              quantity: element.quantity,
              imeiCode: element.imeiCode,
              totalPrice:
                element.quantity * element.productPrice -
                element.discountAmount,
              discount: element.discountAmount,
              discountType: element.discountType,
              billItemType: 2,
              checked: element.isGiftTaken,
              returnPrice: element.repurchasePrice,
              accessoryGroupId: element.accessoryGroupId,
              accessoryGroupCode: element.accessoryGroupCode,
              parentId: element.belongBillDetailId,
              allotmentDiscount: 0,
              allotmentPointAmount: 0,
            };
            this.listBillItem.push(billItem);
          }
        });
        this.mainModel.saleNote = data.data.data.saleNote;
        this.mainModel.customerNote = data.data.data.customerNote;
        this.saleInvoice.saleName = data.data.data.saleName;
        this.saleInvoice.cashierNameBill = data.data.data.cashierName;
        this.mainModel.customerId = data.data.data.customerId;
        this.mainModel.customerMobile = data.data.data.customerPhone;
        this.mainModel.customerAddress = data.data.data.customerAddress;
        this.mainModel.customerName = data.data.data.customerName;
        this.mainModel.customerCity = data.data.data.customerCity;
        this.mainModel.customerWard = data.data.data.customerWard;
        this.mainModel.customerDistrict = data.data.data.customerDistrict;
        this.fetchCity();
        if (this.mainModel.customerCity !== null) {
          this.getDistrict();
        }
        if (this.mainModel.customerDistrict !== null) {
          this.getWard();
        }

        this.saleInvoice.storeName = data.data.data.storeName;
        this.storeName = data.data.data.storeName;
        this.mainModel.orderSourceId = data.data.data.orderSourceId;
        this.mainModel.relatedBillId = data.data.data.id;
        this.saleInvoice.createdAt = data.data.data.createdAt;
        const cashAmount = data.data.data.cashAmount;
        const transferAmount = data.data.data.transferAmount;
        const creditAmount = data.data.data.creditAmount;
        this.saleInvoice.totalPayment =
          cashAmount + transferAmount + creditAmount;
        this.fetchStoreByUser();
      });
    },
    editItem: function (billNumber) {
      this.$router.push({
        name: 'list-return-bill',
        query: { id: billNumber },
      });
    },
    onChangeMoney(type) {
      if (type === 1) {
        this.mainModel.returnFee =
          this.totalPrice -
          unMaskPrice(this.mainModel.cashAmount) -
          unMaskPrice(this.mainModel.transferAmount);
      } else if (type === 2) {
        this.mainModel.returnFee =
          this.totalPrice -
          unMaskPrice(this.mainModel.cashAmount) -
          unMaskPrice(this.mainModel.transferAmount);
      }
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          if (data.data.jobTitleId === 6) {
            this.mainModel.cashierName = data.data.employeeName;
          }
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.selectedClassStore = stores[0].id;
            this.storeName = stores[0].name;
            this.inputSearchStoreProp.disabled = true;
          } else {
            this.options[0].data = [];
            stores.map((element) => {
              this.options[0].data.push(element.name);
            });
            let tmp = [{ data: this.options[0].data }];
            this.filteredOptions = tmp;
          }
        }
        this.fetchCashAccountant();
      });
    },
    getReturnBillByBillId() {
      ApiService.get(`bills/return-bill/${this.billNumber}`).then((data) => {
        const response = data.data.data;
        this.returnBillNumber = response.billNumber;
      });
    },
    roundMoney(value) {
      const tmpValue = Math.round(value / 1000);
      return tmpValue * 1000;
    },
  },
};
</script>

<style>
.headerTable {
  color: rgb(24, 28, 50);
}

.form-group label {
  font-weight: 500;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

.textPayment {
  font-size: 16px;
  color: brown;
  font-weight: 500;
}

.textTitleFinal {
  font-weight: 500;
}

.textFinal {
  font-weight: 500;
  color: brown;
}
.textTitlePayment {
  font-weight: 500;
  font-size: 14px;
}

.textTotalPayment {
  font-weight: 500;
  color: brown;
}
</style>
